header h1 {
    color: #ffffff;
    grid-area: title;
    margin-top: 1px;
    text-align: center;
    font-size: 13px;
    font-family: monospace;
    font-style: normal;
    font-weight: normal;
    margin-left: 1px;
    display: inline-block;
}

a {
    color:rgb(132, 0, 255);
}

a:hover {
    text-shadow: 2px 2px 8px rgb(88, 236, 255);
}
img {
    width: 200px;
    height: 200px;
}